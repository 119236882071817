import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';

import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';


const TOKEN_KEY = 'auth-token';
const USER_ID = 'user-id'

/** ==================QA=======================
  * Sign-on url https://anantham.syngenta.com:665/
 * Reply url: - https://anantham.syngenta.com:665/
 * tenant: '06219a4a-a835-44d5-afaf-3926343bfb89',
 * clientId: 'fab0afc3-fa23-4f4e-8ec7-0f6a83deea36', */

/** ==================DEV=======================
 * Sign-on url https://anantham.syngenta.com:666/
 * Reply url: - https://anantham.syngenta.com:666/
 * tenant: '06219a4a-a835-44d5-afaf-3926343bfb89',
 * clientId: '19aaec94-8745-4e64-8a2c-8dd6adf5d466', */

/** ==================PROD=======================
 * Sign-on url https://anantham.syngenta.com:667/
 * Reply url: - https://anantham.syngenta.com:667/
 * tenant: '06219a4a-a835-44d5-afaf-3926343bfb89',
 * clientId: '89f21133-11c7-44d2-9cbe-9d27b0641b88', */

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // private adalConfig = {
  //   tenant: '06219a4a-a835-44d5-afaf-3926343bfb89',
  //   clientId: '19aaec94-8745-4e64-8a2c-8dd6adf5d466',  //Dev
  // //  clientId: '89f21133-11c7-44d2-9cbe-9d27b0641b88',  //prod
  //   redirectUri: window.location.origin + '/',
  //   postLogoutRedirectUri: window.location.origin + '/',
  //   endpoints: {
  //     "[HOME_URL_WEB_API]": "[HOME_WEB_API_GUID]"
  //   }
  // }

  

  authenticationState = new BehaviorSubject(false);
  constructor(private storage: Storage, private plt: Platform, private msal:MsalService) {
    //this.adal.init(this.adalConfig);
    this.plt.ready().then(() => {
      this.checkToken();
    })
  }

  checkToken() {
    this.storage.get(TOKEN_KEY).then(res => {
      if (res) {
        this.authenticationState.next(true);
      }
    })
  }

  login() {
   // this.adal.login();
   this.msal.loginRedirect();
  }

  logout() {
    this.removeToken();
   // this.adal.logOut();
   this.msal.logout();
  }

  isAuthenticated() {
   // return this.adal.userInfo.authenticated
   if(this.msal.instance.getAllAccounts().length >0){

    return true
    
    }else{
    
    return false;
    
    }
  }

  saveToken(adal: MsalService) {

    // this.storage.set(USER_ID, adal.userInfo.userName).then(() => {
    //   //todo please remove this 
    //   // console.log( adal );
    //   // console.log( adal.userInfo );
    // })
    // return this.storage.set(TOKEN_KEY, 'Bearer ' + environment.adalConfig.clientId).then(() => {
    //   this.authenticationState.next(true);
    // });
  }

  removeToken() {
    return this.storage.remove(TOKEN_KEY).then(() => {
      this.authenticationState.next(false);
    });
  }

  isUserAuthenticated(){
    console.log("isUserAuthenticated called:::");
    this.msal.instance.acquireTokenSilent(environment.adalConfig).then(data=>{    
    console.log('USer Name::::',data);    
    this.storage.set(USER_ID, data.account.username).then(() => {    
    })
    localStorage.setItem('accessToken','Bearer ' + data.accessToken)
    this.storage.set(TOKEN_KEY, 'Bearer ' + data.accessToken).then(() => {    
    this.authenticationState.next(true);    
    });    
    }).catch(error=>{
      //this.logout();
    });

    
    }

}
