import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';


const routes: Routes = [
  { path: '', redirectTo: 'io', pathMatch: 'full' },
  { path: 'login', loadChildren: './public/login/login.module#LoginPageModule' },
  { path: 'dashboard', loadChildren: './members/dashboard/dashboard.module#DashboardPageModule'},
  { path: 'create-indent', loadChildren: './members/create-indent/create-indent.module#CreateIndentPageModule' },
  { path: 'indent-detail', loadChildren: './members/indent-detail/indent-detail.module#IndentDetailPageModule' },
  { path: 'add-item', loadChildren: './members/add-item/add-item.module#AddItemPageModule' },
  { path: 'update-item', loadChildren: './members/update-item/update-item.module#UpdateItemPageModule' },
  { path: 'download', loadChildren: './members/download/download.module#DownloadPageModule' },
  { path: 'upload', loadChildren: './members/upload/upload.module#UploadPageModule' },
  { path: 'upload-master-data', loadChildren: './members/upload-master-data/upload-master-data.module#UploadMasterDataPageModule' },
  { path: 'sap-download-upload', loadChildren: './members/sap-download-upload/sap-download-upload.module#SapDownloadUploadPageModule' },
  { path: 'landing-page', loadChildren: './members/landing-page/landing-page.module#LandingPagePageModule'},
  { path: 'upload-sales-invoice', loadChildren: './members/upload-sales-invoice/upload-sales-invoice.module#UploadSalesInvoicePageModule'},
  { path: 'rgdm-download', loadChildren: './members/rgdm-download/rgdm-download.module#RgdmDownloadPageModule' },
  { path: 'indent-return-dashboard', loadChildren: './members/indent-return-dashboard/indent-return-dashboard.module#IndentReturnDashboardPageModule'},
  { path: 'indent-return-creation', loadChildren: './members/indent-return-creation/indent-return-creation.module#IndentReturnCreationPageModule' },
  { path: 'indent-return-download', loadChildren: './members/indent-return-download/indent-return-download.module#IndentReturnDownloadPageModule' },
  { path: 'indent-return-sap-download-upload', loadChildren: './members/indent-return-sap-download-upload/indent-return-sap-download-upload.module#IndentReturnSapDownloadUploadPageModule' },
  { path: 'indent-return-upload-master-data', loadChildren: './members/indent-return-upload-master-data/indent-return-upload-master-data.module#IndentReturnUploadMasterDataPageModule' },
  { path: 'indent-return-add-item', loadChildren: './members/indent-return-add-item/indent-return-add-item.module#IndentReturnAddItemPageModule' },
  { path: 'indent-return-detail', loadChildren: './members/indent-return-detail/indent-return-detail.module#IndentReturnDetailPageModule' },
  { path: 'indent-return-update-item', loadChildren: './members/indent-return-update-item/indent-return-update-item.module#IndentReturnUpdateItemPageModule' },
  
  {  path: 'members', 
    canActivate: [AuthGuard],
    loadChildren: './members/member-routing.module#MemberRoutingModule'
  },
  

  

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
