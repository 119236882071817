import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Events } from '@ionic/angular';
import { MenuController } from '@ionic/angular';
import { MsalService } from '@azure/msal-angular';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

  userName: any;
  roleName: any;
  isDownload = false;
  isUpload = false;
  isSapUser = false;
  isMasterUpload = false;
  isSalesInvoiceUpload = false;
  isRgdmDownload = false;
  url:any;
  emailApproval: boolean = false;

  constructor(private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public menuCtrl: MenuController,
    private authenticationService: AuthenticationService,
    private router: Router,  private event: Events,private msalService:MsalService,
    private location:Location) {
    this.initializeApp();
  }

  ngOnInit() {
    // this.adal.handleWindowCallback();

    // if (this.adal.userInfo.authenticated) {
    //   this.authenticationService.saveToken(this.adal);
    // }
    this.msalService.instance.handleRedirectPromise().then(res=>{
      if(res!=null && res.account != null){      
      this.msalService.instance.setActiveAccount(res.account);      
      }      
      });
      this.url = this.location.path();
      this.emailApproval = (this.url.indexOf('type=Approve')!=-1 || this.url.indexOf('type=Reject')!=-1);
      if(this.emailApproval)
        localStorage.setItem('url',this.url);
    this.authenticationService.authenticationState.subscribe(state => {
      if (state) {
        if(this.emailApproval)
        this.router.navigate(['indent-detail', { orderId: 
          this.url.substring(this.url.indexOf('orderId=')+8,this.url.indexOf('&type')) }]);
       else 
        this.router.navigate(['members', 'landing-page'], { replaceUrl: true });
      } else {
        this.router.navigate(['login'], { replaceUrl: true });
      }
    });

    
    
   
  }

  initializeApp() {    
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.event.subscribe('user-details', (userdetails) => {
        this.userName = userdetails.userProfile.Uname;
        this.roleName = userdetails.userProfile.RoleDesc
      })

      this.event.subscribe('download', (result) => {
        if (result) {
          this.isDownload = true;
        }
      })

      this.event.subscribe('upload', (result) => {
        if (result) {
          this.isUpload = true;
        }
      })

      this.event.subscribe('sap', (result) => {
        if (result) {
          this.isSapUser = true;
        }
      })
      this.event.subscribe('master', (result) => {
        if (result) {
          this.isMasterUpload = true;
        }
      })

      this.event.subscribe('sales_invoice_upload',(result)=>{
        if(result){
          this.isSalesInvoiceUpload = true;
        }
      })


      this.event.subscribe('Download_RGDM',(result)=>{
        if(result){
          this.isRgdmDownload = true;
        }
      })


    });
  }

  logout() {
    this.menuCtrl.toggle();
    this.authenticationService.logout();
  }

  downloadExcel() {
    this.menuCtrl.toggle();
    this.router.navigate(['members', 'download']);
  }

  uploadExcel() {
    this.menuCtrl.toggle();
    this.router.navigate(['members', 'upload']);
  }

  uploadMasterExcel(){
    this.menuCtrl.toggle();
    this.router.navigate(['members', 'upload-master-data']);
  }

  naviagateToSap(){
    this.menuCtrl.toggle();
    this.router.navigate(['members', 'sap-download-upload']);
  }

  // Indent Return Menus
  naviagateToInvoiceupload(){
    this.menuCtrl.toggle();
    this.router.navigate(['members','upload-sales-invoice']);
  }

  naviagateToRGDMDownload(){
    debugger
    this.menuCtrl.toggle();
    this.router.navigate(['members','rgdm-download']);
  }

  naviagateToIndentReturnDownload(){
    this.menuCtrl.toggle();
    this.router.navigate(['members','indent-return-download']);
  }

  naviagateToSAPIndentReturnDownloadUpload(){
    this.menuCtrl.toggle();
    this.router.navigate(['members','indent-return-sap-download-upload']);
  }
  
  naviagateToIndentReturnMasterUpload(){
    this.menuCtrl.toggle();
    this.router.navigate(['members','indent-return-upload-master-data']);
  }

}
