import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { IonicStorageModule } from '@ionic/storage';

import { HttpClientModule } from '@angular/common/http';

import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { IonicSelectableModule } from 'ionic-selectable';
import { MsalGuard, MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';


const isIE =window.navigator.userAgent.indexOf('MSIE ') > -1 ||window.navigator.userAgent.indexOf('Trident/') > -1;



export function MSALInstanceFactory(): IPublicClientApplication {

  return new PublicClientApplication({

    auth: environment.adalConfig,

    cache: {

      cacheLocation: BrowserCacheLocation.LocalStorage,

      storeAuthStateInCookie: isIE // set to true for IE 11. Remove this line to use Angular Universal

    },



  });

}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule, 
    HttpClientModule,
    IonicSelectableModule,
    IonicStorageModule.forRoot(),
    MsalModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
     
  ],
  providers: [
    StatusBar,
    SplashScreen,
    DatePipe,
    
    // { provide: HTTP_INTERCEPTORS, useClass: AdalInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: MSAL_INSTANCE,useFactory: MSALInstanceFactory},   
    { provide: LocationStrategy, useClass: HashLocationStrategy },   
    MsalGuard,      
    MsalService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
