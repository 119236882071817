import { TestAuthService } from './../services/test-auth.service';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

 
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
 
  constructor(public auth: AuthenticationService, public testAuth : TestAuthService) {}
 
  canActivate(): boolean {
     return this.auth.isAuthenticated();
   //  return true;
  }


}
